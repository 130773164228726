@charset "UTF-8";
/*
Template Name: Srt to Blog - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Icons Css File
*/
@font-face {
  font-family: 'unicons';
  src: url("../fonts/unicons.eot?2256574");
  src: url("../fonts/unicons.eot?2256574#iefix") format("embedded-opentype"), url("../fonts/unicons.woff2?2256574") format("woff2"), url("../fonts/unicons.woff?2256574") format("woff"), url("../fonts/unicons.ttf?2256574") format("truetype"), url("../fonts/unicons.svg?2256574#unicons") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'unicons';
    src: url('../fonts/unicons.svg?2256574#unicons') format('svg');
  }
}
*/
.uil {
  line-height: inherit; }

[class^="uil-"]:before, [class*=" uil-"]:before {
  font-family: "unicons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  font-size: 120%;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.uil-0-plus:before {
  content: '\e800'; }

/* '' */
.uil-10-plus:before {
  content: '\e801'; }

/* '' */
.uil-12-plus:before {
  content: '\e802'; }

/* '' */
.uil-13-plus:before {
  content: '\e803'; }

/* '' */
.uil-16-plus:before {
  content: '\e804'; }

/* '' */
.uil-17-plus:before {
  content: '\e805'; }

/* '' */
.uil-18-plus:before {
  content: '\e806'; }

/* '' */
.uil-21-plus:before {
  content: '\e807'; }

/* '' */
.uil-3-plus:before {
  content: '\e808'; }

/* '' */
.uil-500px:before {
  content: '\e809'; }

/* '' */
.uil-6-plus:before {
  content: '\e80a'; }

/* '' */
.uil-abacus:before {
  content: '\e80b'; }

/* '' */
.uil-accessible-icon-alt:before {
  content: '\e80c'; }

/* '' */
.uil-adjust-alt:before {
  content: '\e80d'; }

/* '' */
.uil-adjust-circle:before {
  content: '\e80e'; }

/* '' */
.uil-adjust-half:before {
  content: '\e80f'; }

/* '' */
.uil-adjust:before {
  content: '\e810'; }

/* '' */
.uil-adobe-alt:before {
  content: '\e811'; }

/* '' */
.uil-adobe:before {
  content: '\e812'; }

/* '' */
.uil-airplay:before {
  content: '\e813'; }

/* '' */
.uil-align-alt:before {
  content: '\e814'; }

/* '' */
.uil-align-center-alt:before {
  content: '\e815'; }

/* '' */
.uil-align-center-h:before {
  content: '\e816'; }

/* '' */
.uil-align-center-justify:before {
  content: '\e817'; }

/* '' */
.uil-align-center-v:before {
  content: '\e818'; }

/* '' */
.uil-align-center:before {
  content: '\e819'; }

/* '' */
.uil-align-justify:before {
  content: '\e81a'; }

/* '' */
.uil-align-left-justify:before {
  content: '\e81b'; }

/* '' */
.uil-align-left:before {
  content: '\e81c'; }

/* '' */
.uil-align-letter-right:before {
  content: '\e81d'; }

/* '' */
.uil-align-right-justify:before {
  content: '\e81e'; }

/* '' */
.uil-align-right:before {
  content: '\e81f'; }

/* '' */
.uil-align:before {
  content: '\e820'; }

/* '' */
.uil-amazon:before {
  content: '\e821'; }

/* '' */
.uil-ambulance:before {
  content: '\e822'; }

/* '' */
.uil-analysis:before {
  content: '\e823'; }

/* '' */
.uil-analytics:before {
  content: '\e824'; }

/* '' */
.uil-anchor:before {
  content: '\e825'; }

/* '' */
.uil-android-alt:before {
  content: '\e826'; }

/* '' */
.uil-android-phone-slash:before {
  content: '\e827'; }

/* '' */
.uil-android:before {
  content: '\e828'; }

/* '' */
.uil-angle-double-down:before {
  content: '\e829'; }

/* '' */
.uil-angle-double-left:before {
  content: '\e82a'; }

/* '' */
.uil-angle-double-right:before {
  content: '\e82b'; }

/* '' */
.uil-angle-double-up:before {
  content: '\e82c'; }

/* '' */
.uil-angle-down:before {
  content: '\e82d'; }

/* '' */
.uil-angle-left:before {
  content: '\e82e'; }

/* '' */
.uil-angle-right-b:before {
  content: '\e82f'; }

/* '' */
.uil-angle-right:before {
  content: '\e830'; }

/* '' */
.uil-angle-up:before {
  content: '\e831'; }

/* '' */
.uil-angry:before {
  content: '\e832'; }

/* '' */
.uil-ankh:before {
  content: '\e833'; }

/* '' */
.uil-annoyed-alt:before {
  content: '\e834'; }

/* '' */
.uil-annoyed:before {
  content: '\e835'; }

/* '' */
.uil-apple-alt:before {
  content: '\e836'; }

/* '' */
.uil-apple:before {
  content: '\e837'; }

/* '' */
.uil-apps:before {
  content: '\e838'; }

/* '' */
.uil-archive-alt:before {
  content: '\e839'; }

/* '' */
.uil-archive:before {
  content: '\e83a'; }

/* '' */
.uil-archway:before {
  content: '\e83b'; }

/* '' */
.uil-arrow-break:before {
  content: '\e83c'; }

/* '' */
.uil-arrow-circle-down:before {
  content: '\e83d'; }

/* '' */
.uil-arrow-circle-left:before {
  content: '\e83e'; }

/* '' */
.uil-arrow-circle-right:before {
  content: '\e83f'; }

/* '' */
.uil-arrow-circle-up:before {
  content: '\e840'; }

/* '' */
.uil-arrow-compress-h:before {
  content: '\e841'; }

/* '' */
.uil-arrow-down-left:before {
  content: '\e842'; }

/* '' */
.uil-arrow-down-right:before {
  content: '\e843'; }

/* '' */
.uil-arrow-down:before {
  content: '\e844'; }

/* '' */
.uil-arrow-from-right:before {
  content: '\e845'; }

/* '' */
.uil-arrow-from-top:before {
  content: '\e846'; }

/* '' */
.uil-arrow-growth:before {
  content: '\e847'; }

/* '' */
.uil-arrow-left:before {
  content: '\e848'; }

/* '' */
.uil-arrow-random:before {
  content: '\e849'; }

/* '' */
.uil-arrow-resize-diagonal:before {
  content: '\e84a'; }

/* '' */
.uil-arrow-right:before {
  content: '\e84b'; }

/* '' */
.uil-arrow-to-bottom:before {
  content: '\e84c'; }

/* '' */
.uil-arrow-to-right:before {
  content: '\e84d'; }

/* '' */
.uil-arrow-up-left:before {
  content: '\e84e'; }

/* '' */
.uil-arrow-up-right:before {
  content: '\e84f'; }

/* '' */
.uil-arrow-up:before {
  content: '\e850'; }

/* '' */
.uil-arrows-h-alt:before {
  content: '\e851'; }

/* '' */
.uil-arrows-h:before {
  content: '\e852'; }

/* '' */
.uil-arrows-left-down:before {
  content: '\e853'; }

/* '' */
.uil-arrows-maximize:before {
  content: '\e854'; }

/* '' */
.uil-arrows-merge:before {
  content: '\e855'; }

/* '' */
.uil-arrows-resize-h:before {
  content: '\e856'; }

/* '' */
.uil-arrows-resize-v:before {
  content: '\e857'; }

/* '' */
.uil-arrows-resize:before {
  content: '\e858'; }

/* '' */
.uil-arrows-right-down:before {
  content: '\e859'; }

/* '' */
.uil-arrows-shrink-h:before {
  content: '\e85a'; }

/* '' */
.uil-arrows-shrink-v:before {
  content: '\e85b'; }

/* '' */
.uil-arrows-up-right:before {
  content: '\e85c'; }

/* '' */
.uil-arrows-v-alt:before {
  content: '\e85d'; }

/* '' */
.uil-arrows-v:before {
  content: '\e85e'; }

/* '' */
.uil-assistive-listening-systems:before {
  content: '\e85f'; }

/* '' */
.uil-asterisk:before {
  content: '\e860'; }

/* '' */
.uil-at:before {
  content: '\e861'; }

/* '' */
.uil-atm-card:before {
  content: '\e862'; }

/* '' */
.uil-atom:before {
  content: '\e863'; }

/* '' */
.uil-auto-flash:before {
  content: '\e864'; }

/* '' */
.uil-award-alt:before {
  content: '\e865'; }

/* '' */
.uil-award:before {
  content: '\e866'; }

/* '' */
.uil-baby-carriage:before {
  content: '\e867'; }

/* '' */
.uil-backpack:before {
  content: '\e868'; }

/* '' */
.uil-backspace:before {
  content: '\e869'; }

/* '' */
.uil-backward:before {
  content: '\e86a'; }

/* '' */
.uil-bag-alt:before {
  content: '\e86b'; }

/* '' */
.uil-bag-slash:before {
  content: '\e86c'; }

/* '' */
.uil-bag:before {
  content: '\e86d'; }

/* '' */
.uil-balance-scale:before {
  content: '\e86e'; }

/* '' */
.uil-ball:before {
  content: '\e86f'; }

/* '' */
.uil-ban:before {
  content: '\e870'; }

/* '' */
.uil-bars:before {
  content: '\e871'; }

/* '' */
.uil-baseball-ball:before {
  content: '\e872'; }

/* '' */
.uil-basketball-hoop:before {
  content: '\e873'; }

/* '' */
.uil-basketball:before {
  content: '\e874'; }

/* '' */
.uil-bath:before {
  content: '\e875'; }

/* '' */
.uil-battery-bolt:before {
  content: '\e876'; }

/* '' */
.uil-battery-empty:before {
  content: '\e877'; }

/* '' */
.uil-bed-double:before {
  content: '\e878'; }

/* '' */
.uil-bed:before {
  content: '\e879'; }

/* '' */
.uil-behance-alt:before {
  content: '\e87a'; }

/* '' */
.uil-behance:before {
  content: '\e87b'; }

/* '' */
.uil-bell-school:before {
  content: '\e87c'; }

/* '' */
.uil-bell-slash:before {
  content: '\e87d'; }

/* '' */
.uil-bell:before {
  content: '\e87e'; }

/* '' */
.uil-bill:before {
  content: '\e87f'; }

/* '' */
.uil-bitcoin-alt:before {
  content: '\e880'; }

/* '' */
.uil-bitcoin-bold:before {
  content: '\e881'; }

/* '' */
.uil-bitcoin-circle:before {
  content: '\e882'; }

/* '' */
.uil-bitcoin:before {
  content: '\e883'; }

/* '' */
.uil-black-berry:before {
  content: '\e884'; }

/* '' */
.uil-blogger-alt:before {
  content: '\e885'; }

/* '' */
.uil-blogger:before {
  content: '\e886'; }

/* '' */
.uil-bluetooth-b:before {
  content: '\e887'; }

/* '' */
.uil-bold:before {
  content: '\e888'; }

/* '' */
.uil-bolt-alt:before {
  content: '\e889'; }

/* '' */
.uil-bolt-slash:before {
  content: '\e88a'; }

/* '' */
.uil-bolt:before {
  content: '\e88b'; }

/* '' */
.uil-book-alt:before {
  content: '\e88c'; }

/* '' */
.uil-book-medical:before {
  content: '\e88d'; }

/* '' */
.uil-book-open:before {
  content: '\e88e'; }

/* '' */
.uil-book-reader:before {
  content: '\e88f'; }

/* '' */
.uil-book:before {
  content: '\e890'; }

/* '' */
.uil-bookmark-full:before {
  content: '\e891'; }

/* '' */
.uil-bookmark:before {
  content: '\e892'; }

/* '' */
.uil-books:before {
  content: '\e893'; }

/* '' */
.uil-boombox:before {
  content: '\e894'; }

/* '' */
.uil-border-alt:before {
  content: '\e895'; }

/* '' */
.uil-border-bottom:before {
  content: '\e896'; }

/* '' */
.uil-border-clear:before {
  content: '\e897'; }

/* '' */
.uil-border-horizontal:before {
  content: '\e898'; }

/* '' */
.uil-border-inner:before {
  content: '\e899'; }

/* '' */
.uil-border-left:before {
  content: '\e89a'; }

/* '' */
.uil-border-out:before {
  content: '\e89b'; }

/* '' */
.uil-border-right:before {
  content: '\e89c'; }

/* '' */
.uil-border-top:before {
  content: '\e89d'; }

/* '' */
.uil-border-vertical:before {
  content: '\e89e'; }

/* '' */
.uil-bowling-ball:before {
  content: '\e89f'; }

/* '' */
.uil-box:before {
  content: '\e8a0'; }

/* '' */
.uil-briefcase-alt:before {
  content: '\e8a1'; }

/* '' */
.uil-briefcase:before {
  content: '\e8a2'; }

/* '' */
.uil-bright:before {
  content: '\e8a3'; }

/* '' */
.uil-brightness-empty:before {
  content: '\e8a4'; }

/* '' */
.uil-brightness-half:before {
  content: '\e8a5'; }

/* '' */
.uil-brightness-low:before {
  content: '\e8a6'; }

/* '' */
.uil-brightness-minus:before {
  content: '\e8a7'; }

/* '' */
.uil-brightness-plus:before {
  content: '\e8a8'; }

/* '' */
.uil-brightness:before {
  content: '\e8a9'; }

/* '' */
.uil-bring-bottom:before {
  content: '\e8aa'; }

/* '' */
.uil-bring-front:before {
  content: '\e8ab'; }

/* '' */
.uil-brush-alt:before {
  content: '\e8ac'; }

/* '' */
.uil-bug:before {
  content: '\e8ad'; }

/* '' */
.uil-building:before {
  content: '\e8ae'; }

/* '' */
.uil-bullseye:before {
  content: '\e8af'; }

/* '' */
.uil-bus-alt:before {
  content: '\e8b0'; }

/* '' */
.uil-bus-school:before {
  content: '\e8b1'; }

/* '' */
.uil-bus:before {
  content: '\e8b2'; }

/* '' */
.uil-calcualtor:before {
  content: '\e8b3'; }

/* '' */
.uil-calculator-alt:before {
  content: '\e8b4'; }

/* '' */
.uil-calculator:before {
  content: '\e8b5'; }

/* '' */
.uil-calendar-alt:before {
  content: '\e8b6'; }

/* '' */
.uil-calendar-slash:before {
  content: '\e8b7'; }

/* '' */
.uil-calender:before {
  content: '\e8b8'; }

/* '' */
.uil-calling:before {
  content: '\e8b9'; }

/* '' */
.uil-camera-change:before {
  content: '\e8ba'; }

/* '' */
.uil-camera-plus:before {
  content: '\e8bb'; }

/* '' */
.uil-camera-slash:before {
  content: '\e8bc'; }

/* '' */
.uil-camera:before {
  content: '\e8bd'; }

/* '' */
.uil-cancel:before {
  content: '\e8be'; }

/* '' */
.uil-capsule:before {
  content: '\e8bf'; }

/* '' */
.uil-capture:before {
  content: '\e8c0'; }

/* '' */
.uil-car-sideview:before {
  content: '\e8c1'; }

/* '' */
.uil-car-slash:before {
  content: '\e8c2'; }

/* '' */
.uil-car-wash:before {
  content: '\e8c3'; }

/* '' */
.uil-car:before {
  content: '\e8c4'; }

/* '' */
.uil-card-atm:before {
  content: '\e8c5'; }

/* '' */
.uil-caret-right:before {
  content: '\e8c6'; }

/* '' */
.uil-cart:before {
  content: '\e8c7'; }

/* '' */
.uil-cell:before {
  content: '\e8c8'; }

/* '' */
.uil-celsius:before {
  content: '\e8c9'; }

/* '' */
.uil-chart-bar-alt:before {
  content: '\e8ca'; }

/* '' */
.uil-chart-bar:before {
  content: '\e8cb'; }

/* '' */
.uil-chart-down:before {
  content: '\e8cc'; }

/* '' */
.uil-chart-growth-alt:before {
  content: '\e8cd'; }

/* '' */
.uil-chart-growth:before {
  content: '\e8ce'; }

/* '' */
.uil-chart-line:before {
  content: '\e8cf'; }

/* '' */
.uil-chart-pie-alt:before {
  content: '\e8d0'; }

/* '' */
.uil-chart-pie:before {
  content: '\e8d1'; }

/* '' */
.uil-chart:before {
  content: '\e8d2'; }

/* '' */
.uil-chat-bubble-user:before {
  content: '\e8d3'; }

/* '' */
.uil-chat-info:before {
  content: '\e8d4'; }

/* '' */
.uil-chat:before {
  content: '\e8d5'; }

/* '' */
.uil-check-circle:before {
  content: '\e8d6'; }

/* '' */
.uil-check-square:before {
  content: '\e8d7'; }

/* '' */
.uil-check:before {
  content: '\e8d8'; }

/* '' */
.uil-circle-layer:before {
  content: '\e8d9'; }

/* '' */
.uil-circle:before {
  content: '\e8da'; }

/* '' */
.uil-circuit:before {
  content: '\e8db'; }

/* '' */
.uil-clapper-board:before {
  content: '\e8dc'; }

/* '' */
.uil-clipboard-alt:before {
  content: '\e8dd'; }

/* '' */
.uil-clipboard-blank:before {
  content: '\e8de'; }

/* '' */
.uil-clipboard-notes:before {
  content: '\e8df'; }

/* '' */
.uil-clipboard:before {
  content: '\e8e0'; }

/* '' */
.uil-clock-eight:before {
  content: '\e8e1'; }

/* '' */
.uil-clock-five:before {
  content: '\e8e2'; }

/* '' */
.uil-clock-nine:before {
  content: '\e8e3'; }

/* '' */
.uil-clock-seven:before {
  content: '\e8e4'; }

/* '' */
.uil-clock-ten:before {
  content: '\e8e5'; }

/* '' */
.uil-clock-three:before {
  content: '\e8e6'; }

/* '' */
.uil-clock-two:before {
  content: '\e8e7'; }

/* '' */
.uil-clock:before {
  content: '\e8e8'; }

/* '' */
.uil-closed-captioning:before {
  content: '\e8e9'; }

/* '' */
.uil-cloud-block:before {
  content: '\e8ea'; }

/* '' */
.uil-cloud-bookmark:before {
  content: '\e8eb'; }

/* '' */
.uil-cloud-check:before {
  content: '\e8ec'; }

/* '' */
.uil-cloud-computing:before {
  content: '\e8ed'; }

/* '' */
.uil-cloud-data-connection:before {
  content: '\e8ee'; }

/* '' */
.uil-cloud-database-tree:before {
  content: '\e8ef'; }

/* '' */
.uil-cloud-download:before {
  content: '\e8f0'; }

/* '' */
.uil-cloud-drizzle:before {
  content: '\e8f1'; }

/* '' */
.uil-cloud-exclamation:before {
  content: '\e8f2'; }

/* '' */
.uil-cloud-hail:before {
  content: '\e8f3'; }

/* '' */
.uil-cloud-heart:before {
  content: '\e8f4'; }

/* '' */
.uil-cloud-info:before {
  content: '\e8f5'; }

/* '' */
.uil-cloud-lock:before {
  content: '\e8f6'; }

/* '' */
.uil-cloud-meatball:before {
  content: '\e8f7'; }

/* '' */
.uil-cloud-moon-hail:before {
  content: '\e8f8'; }

/* '' */
.uil-cloud-moon-meatball:before {
  content: '\e8f9'; }

/* '' */
.uil-cloud-moon-rain:before {
  content: '\e8fa'; }

/* '' */
.uil-cloud-moon-showers:before {
  content: '\e8fb'; }

/* '' */
.uil-cloud-moon:before {
  content: '\e8fc'; }

/* '' */
.uil-cloud-question:before {
  content: '\e8fd'; }

/* '' */
.uil-cloud-rain-sun:before {
  content: '\e8fe'; }

/* '' */
.uil-cloud-rain:before {
  content: '\e8ff'; }

/* '' */
.uil-cloud-redo:before {
  content: '\e900'; }

/* '' */
.uil-cloud-set:before {
  content: '\e901'; }

/* '' */
.uil-cloud-share:before {
  content: '\e902'; }

/* '' */
.uil-cloud-shield:before {
  content: '\e903'; }

/* '' */
.uil-cloud-showers-alt:before {
  content: '\e904'; }

/* '' */
.uil-cloud-showers-heavy:before {
  content: '\e905'; }

/* '' */
.uil-cloud-showers:before {
  content: '\e906'; }

/* '' */
.uil-cloud-slash:before {
  content: '\e907'; }

/* '' */
.uil-cloud-sun-hail:before {
  content: '\e908'; }

/* '' */
.uil-cloud-sun-meatball:before {
  content: '\e909'; }

/* '' */
.uil-cloud-sun-rain-alt:before {
  content: '\e90a'; }

/* '' */
.uil-cloud-sun-rain:before {
  content: '\e90b'; }

/* '' */
.uil-cloud-sun-tear:before {
  content: '\e90c'; }

/* '' */
.uil-cloud-sun:before {
  content: '\e90d'; }

/* '' */
.uil-cloud-times:before {
  content: '\e90e'; }

/* '' */
.uil-cloud-unlock:before {
  content: '\e90f'; }

/* '' */
.uil-cloud-upload:before {
  content: '\e910'; }

/* '' */
.uil-cloud-wifi:before {
  content: '\e911'; }

/* '' */
.uil-cloud-wind:before {
  content: '\e912'; }

/* '' */
.uil-cloud:before {
  content: '\e913'; }

/* '' */
.uil-clouds:before {
  content: '\e914'; }

/* '' */
.uil-club:before {
  content: '\e915'; }

/* '' */
.uil-code:before {
  content: '\e916'; }

/* '' */
.uil-coffee:before {
  content: '\e917'; }

/* '' */
.uil-cog:before {
  content: '\e918'; }

/* '' */
.uil-coins:before {
  content: '\e919'; }

/* '' */
.uil-columns:before {
  content: '\e91a'; }

/* '' */
.uil-comment-alt-block:before {
  content: '\e91b'; }

/* '' */
.uil-comment-alt-chart-lines:before {
  content: '\e91c'; }

/* '' */
.uil-comment-alt-check:before {
  content: '\e91d'; }

/* '' */
.uil-comment-alt-dots:before {
  content: '\e91e'; }

/* '' */
.uil-comment-alt-download:before {
  content: '\e91f'; }

/* '' */
.uil-comment-alt-edit:before {
  content: '\e920'; }

/* '' */
.uil-comment-alt-exclamation:before {
  content: '\e921'; }

/* '' */
.uil-comment-alt-heart:before {
  content: '\e922'; }

/* '' */
.uil-comment-alt-image:before {
  content: '\e923'; }

/* '' */
.uil-comment-alt-info:before {
  content: '\e924'; }

/* '' */
.uil-comment-alt-lines:before {
  content: '\e925'; }

/* '' */
.uil-comment-alt-lock:before {
  content: '\e926'; }

/* '' */
.uil-comment-alt-medical:before {
  content: '\e927'; }

/* '' */
.uil-comment-alt-message:before {
  content: '\e928'; }

/* '' */
.uil-comment-alt-notes:before {
  content: '\e929'; }

/* '' */
.uil-comment-alt-plus:before {
  content: '\e92a'; }

/* '' */
.uil-comment-alt-question:before {
  content: '\e92b'; }

/* '' */
.uil-comment-alt-redo:before {
  content: '\e92c'; }

/* '' */
.uil-comment-alt-search:before {
  content: '\e92d'; }

/* '' */
.uil-comment-alt-share:before {
  content: '\e92e'; }

/* '' */
.uil-comment-alt-shield:before {
  content: '\e92f'; }

/* '' */
.uil-comment-alt-slash:before {
  content: '\e930'; }

/* '' */
.uil-comment-alt-upload:before {
  content: '\e931'; }

/* '' */
.uil-comment-alt-verify:before {
  content: '\e932'; }

/* '' */
.uil-comment-alt:before {
  content: '\e933'; }

/* '' */
.uil-comment-block:before {
  content: '\e934'; }

/* '' */
.uil-comment-chart-line:before {
  content: '\e935'; }

/* '' */
.uil-comment-check:before {
  content: '\e936'; }

/* '' */
.uil-comment-dots:before {
  content: '\e937'; }

/* '' */
.uil-comment-download:before {
  content: '\e938'; }

/* '' */
.uil-comment-edit:before {
  content: '\e939'; }

/* '' */
.uil-comment-exclamation:before {
  content: '\e93a'; }

/* '' */
.uil-comment-heart:before {
  content: '\e93b'; }

/* '' */
.uil-comment-image:before {
  content: '\e93c'; }

/* '' */
.uil-comment-info-alt:before {
  content: '\e93d'; }

/* '' */
.uil-comment-info:before {
  content: '\e93e'; }

/* '' */
.uil-comment-lines:before {
  content: '\e93f'; }

/* '' */
.uil-comment-lock:before {
  content: '\e940'; }

/* '' */
.uil-comment-medical:before {
  content: '\e941'; }

/* '' */
.uil-comment-message:before {
  content: '\e942'; }

/* '' */
.uil-comment-notes:before {
  content: '\e943'; }

/* '' */
.uil-comment-plus:before {
  content: '\e944'; }

/* '' */
.uil-comment-question:before {
  content: '\e945'; }

/* '' */
.uil-comment-redo:before {
  content: '\e946'; }

/* '' */
.uil-comment-search:before {
  content: '\e947'; }

/* '' */
.uil-comment-share:before {
  content: '\e948'; }

/* '' */
.uil-comment-shield:before {
  content: '\e949'; }

/* '' */
.uil-comment-slash:before {
  content: '\e94a'; }

/* '' */
.uil-comment-upload:before {
  content: '\e94b'; }

/* '' */
.uil-comment-verify:before {
  content: '\e94c'; }

/* '' */
.uil-comment:before {
  content: '\e94d'; }

/* '' */
.uil-comments-alt:before {
  content: '\e94e'; }

/* '' */
.uil-comments:before {
  content: '\e94f'; }

/* '' */
.uil-commnet-alt-slash:before {
  content: '\e950'; }

/* '' */
.uil-compact-disc:before {
  content: '\e951'; }

/* '' */
.uil-compass:before {
  content: '\e952'; }

/* '' */
.uil-compress-alt-left:before {
  content: '\e953'; }

/* '' */
.uil-compress-alt:before {
  content: '\e954'; }

/* '' */
.uil-compress-arrows:before {
  content: '\e955'; }

/* '' */
.uil-compress-lines:before {
  content: '\e956'; }

/* '' */
.uil-compress-point:before {
  content: '\e957'; }

/* '' */
.uil-compress-v:before {
  content: '\e958'; }

/* '' */
.uil-compress:before {
  content: '\e959'; }

/* '' */
.uil-computer-mouse:before {
  content: '\e95a'; }

/* '' */
.uil-confused:before {
  content: '\e95b'; }

/* '' */
.uil-constructor:before {
  content: '\e95c'; }

/* '' */
.uil-copy-alt:before {
  content: '\e95d'; }

/* '' */
.uil-copy-landscape:before {
  content: '\e95e'; }

/* '' */
.uil-copy:before {
  content: '\e95f'; }

/* '' */
.uil-copyright:before {
  content: '\e960'; }

/* '' */
.uil-corner-down-left:before {
  content: '\e961'; }

/* '' */
.uil-corner-down-right-alt:before {
  content: '\e962'; }

/* '' */
.uil-corner-down-right:before {
  content: '\e963'; }

/* '' */
.uil-corner-left-down:before {
  content: '\e964'; }

/* '' */
.uil-corner-right-down:before {
  content: '\e965'; }

/* '' */
.uil-corner-up-left-alt:before {
  content: '\e966'; }

/* '' */
.uil-corner-up-left:before {
  content: '\e967'; }

/* '' */
.uil-corner-up-right-alt:before {
  content: '\e968'; }

/* '' */
.uil-corner-up-right:before {
  content: '\e969'; }

/* '' */
.uil-creative-commons-pd-alt:before {
  content: '\e96a'; }

/* '' */
.uil-creative-commons-pd:before {
  content: '\e96b'; }

/* '' */
.uil-crockery:before {
  content: '\e96c'; }

/* '' */
.uil-crop-alt-rotate-left:before {
  content: '\e96d'; }

/* '' */
.uil-crop-alt-rotate-right:before {
  content: '\e96e'; }

/* '' */
.uil-crop-alt:before {
  content: '\e96f'; }

/* '' */
.uil-crosshair-alt:before {
  content: '\e970'; }

/* '' */
.uil-crosshair:before {
  content: '\e971'; }

/* '' */
.uil-crosshairs:before {
  content: '\e972'; }

/* '' */
.uil-cube:before {
  content: '\e973'; }

/* '' */
.uil-dashboard:before {
  content: '\e974'; }

/* '' */
.uil-data-sharing:before {
  content: '\e975'; }

/* '' */
.uil-database-alt:before {
  content: '\e976'; }

/* '' */
.uil-database:before {
  content: '\e977'; }

/* '' */
.uil-desert:before {
  content: '\e978'; }

/* '' */
.uil-desktop-alt-slash:before {
  content: '\e979'; }

/* '' */
.uil-desktop-alt:before {
  content: '\e97a'; }

/* '' */
.uil-desktop-cloud-alt:before {
  content: '\e97b'; }

/* '' */
.uil-desktop-slash:before {
  content: '\e97c'; }

/* '' */
.uil-desktop:before {
  content: '\e97d'; }

/* '' */
.uil-dialpad-alt:before {
  content: '\e97e'; }

/* '' */
.uil-dialpad:before {
  content: '\e97f'; }

/* '' */
.uil-diamond:before {
  content: '\e980'; }

/* '' */
.uil-diary-alt:before {
  content: '\e981'; }

/* '' */
.uil-diary:before {
  content: '\e982'; }

/* '' */
.uil-dice-five:before {
  content: '\e983'; }

/* '' */
.uil-dice-four:before {
  content: '\e984'; }

/* '' */
.uil-dice-one:before {
  content: '\e985'; }

/* '' */
.uil-dice-six:before {
  content: '\e986'; }

/* '' */
.uil-dice-three:before {
  content: '\e987'; }

/* '' */
.uil-dice-two:before {
  content: '\e988'; }

/* '' */
.uil-direction:before {
  content: '\e989'; }

/* '' */
.uil-directions:before {
  content: '\e98a'; }

/* '' */
.uil-dizzy-meh:before {
  content: '\e98b'; }

/* '' */
.uil-dna:before {
  content: '\e98c'; }

/* '' */
.uil-document-layout-center:before {
  content: '\e98d'; }

/* '' */
.uil-document-layout-left:before {
  content: '\e98e'; }

/* '' */
.uil-document-layout-right:before {
  content: '\e98f'; }

/* '' */
.uil-document:before {
  content: '\e990'; }

/* '' */
.uil-dollar-alt:before {
  content: '\e991'; }

/* '' */
.uil-dollar-sign-alt:before {
  content: '\e992'; }

/* '' */
.uil-dollar-sign:before {
  content: '\e993'; }

/* '' */
.uil-down-arrow:before {
  content: '\e994'; }

/* '' */
.uil-download-alt:before {
  content: '\e995'; }

/* '' */
.uil-dribbble:before {
  content: '\e996'; }

/* '' */
.uil-drill:before {
  content: '\e997'; }

/* '' */
.uil-dropbox:before {
  content: '\e998'; }

/* '' */
.uil-dumbbell:before {
  content: '\e999'; }

/* '' */
.uil-ear:before {
  content: '\e99a'; }

/* '' */
.uil-edit-alt:before {
  content: '\e99b'; }

/* '' */
.uil-edit:before {
  content: '\e99c'; }

/* '' */
.uil-ellipsis-h:before {
  content: '\e99d'; }

/* '' */
.uil-ellipsis-v:before {
  content: '\e99e'; }

/* '' */
.uil-emoji:before {
  content: '\e99f'; }

/* '' */
.uil-enter:before {
  content: '\e9a0'; }

/* '' */
.uil-entry:before {
  content: '\e9a1'; }

/* '' */
.uil-envelope-add:before {
  content: '\e9a2'; }

/* '' */
.uil-envelope-alt:before {
  content: '\e9a3'; }

/* '' */
.uil-envelope-block:before {
  content: '\e9a4'; }

/* '' */
.uil-envelope-bookmark:before {
  content: '\e9a5'; }

/* '' */
.uil-envelope-check:before {
  content: '\e9a6'; }

/* '' */
.uil-envelope-download-alt:before {
  content: '\e9a7'; }

/* '' */
.uil-envelope-download:before {
  content: '\e9a8'; }

/* '' */
.uil-envelope-edit:before {
  content: '\e9a9'; }

/* '' */
.uil-envelope-exclamation:before {
  content: '\e9aa'; }

/* '' */
.uil-envelope-heart:before {
  content: '\e9ab'; }

/* '' */
.uil-envelope-info:before {
  content: '\e9ac'; }

/* '' */
.uil-envelope-lock:before {
  content: '\e9ad'; }

/* '' */
.uil-envelope-minus:before {
  content: '\e9ae'; }

/* '' */
.uil-envelope-open:before {
  content: '\e9af'; }

/* '' */
.uil-envelope-question:before {
  content: '\e9b0'; }

/* '' */
.uil-envelope-receive:before {
  content: '\e9b1'; }

/* '' */
.uil-envelope-redo:before {
  content: '\e9b2'; }

/* '' */
.uil-envelope-search:before {
  content: '\e9b3'; }

/* '' */
.uil-envelope-send:before {
  content: '\e9b4'; }

/* '' */
.uil-envelope-share:before {
  content: '\e9b5'; }

/* '' */
.uil-envelope-shield:before {
  content: '\e9b6'; }

/* '' */
.uil-envelope-star:before {
  content: '\e9b7'; }

/* '' */
.uil-envelope-times:before {
  content: '\e9b8'; }

/* '' */
.uil-envelope-upload-alt:before {
  content: '\e9b9'; }

/* '' */
.uil-envelope-upload:before {
  content: '\e9ba'; }

/* '' */
.uil-envelope:before {
  content: '\e9bb'; }

/* '' */
.uil-envelopes:before {
  content: '\e9bc'; }

/* '' */
.uil-equal-circle:before {
  content: '\e9bd'; }

/* '' */
.uil-euro-circle:before {
  content: '\e9be'; }

/* '' */
.uil-euro:before {
  content: '\e9bf'; }

/* '' */
.uil-exchange-alt:before {
  content: '\e9c0'; }

/* '' */
.uil-exchange:before {
  content: '\e9c1'; }

/* '' */
.uil-exclamation-circle:before {
  content: '\e9c2'; }

/* '' */
.uil-exclamation-octagon:before {
  content: '\e9c3'; }

/* '' */
.uil-exclamation-triangle:before {
  content: '\e9c4'; }

/* '' */
.uil-exclude:before {
  content: '\e9c5'; }

/* '' */
.uil-exit:before {
  content: '\e9c6'; }

/* '' */
.uil-expand-alt:before {
  content: '\e9c7'; }

/* '' */
.uil-expand-arrows-alt:before {
  content: '\e9c8'; }

/* '' */
.uil-expand-arrows:before {
  content: '\e9c9'; }

/* '' */
.uil-expand-from-corner:before {
  content: '\e9ca'; }

/* '' */
.uil-expand-left:before {
  content: '\e9cb'; }

/* '' */
.uil-expand-right:before {
  content: '\e9cc'; }

/* '' */
.uil-export:before {
  content: '\e9cd'; }

/* '' */
.uil-exposure-alt:before {
  content: '\e9ce'; }

/* '' */
.uil-exposure-increase:before {
  content: '\e9cf'; }

/* '' */
.uil-external-link-alt:before {
  content: '\e9d0'; }

/* '' */
.uil-eye-slash:before {
  content: '\e9d1'; }

/* '' */
.uil-eye:before {
  content: '\e9d2'; }

/* '' */
.uil-facebook-f:before {
  content: '\e9d3'; }

/* '' */
.uil-facebook-messenger-alt:before {
  content: '\e9d4'; }

/* '' */
.uil-facebook-messenger:before {
  content: '\e9d5'; }

/* '' */
.uil-facebook:before {
  content: '\e9d6'; }

/* '' */
.uil-fahrenheit:before {
  content: '\e9d7'; }

/* '' */
.uil-fast-mail-alt:before {
  content: '\e9d8'; }

/* '' */
.uil-fast-mail:before {
  content: '\e9d9'; }

/* '' */
.uil-favorite:before {
  content: '\e9da'; }

/* '' */
.uil-feedback:before {
  content: '\e9db'; }

/* '' */
.uil-file-alt:before {
  content: '\e9dc'; }

/* '' */
.uil-file-blank:before {
  content: '\e9dd'; }

/* '' */
.uil-file-block-alt:before {
  content: '\e9de'; }

/* '' */
.uil-file-bookmark-alt:before {
  content: '\e9df'; }

/* '' */
.uil-file-check-alt:before {
  content: '\e9e0'; }

/* '' */
.uil-file-check:before {
  content: '\e9e1'; }

/* '' */
.uil-file-contract-dollar:before {
  content: '\e9e2'; }

/* '' */
.uil-file-copy-alt:before {
  content: '\e9e3'; }

/* '' */
.uil-file-download-alt:before {
  content: '\e9e4'; }

/* '' */
.uil-file-download:before {
  content: '\e9e5'; }

/* '' */
.uil-file-edit-alt:before {
  content: '\e9e6'; }

/* '' */
.uil-file-exclamation-alt:before {
  content: '\e9e7'; }

/* '' */
.uil-file-exclamation:before {
  content: '\e9e8'; }

/* '' */
.uil-file-heart:before {
  content: '\e9e9'; }

/* '' */
.uil-file-info-alt:before {
  content: '\e9ea'; }

/* '' */
.uil-file-landscape-alt:before {
  content: '\e9eb'; }

/* '' */
.uil-file-landscape:before {
  content: '\e9ec'; }

/* '' */
.uil-file-lanscape-slash:before {
  content: '\e9ed'; }

/* '' */
.uil-file-lock-alt:before {
  content: '\e9ee'; }

/* '' */
.uil-file-medical-alt:before {
  content: '\e9ef'; }

/* '' */
.uil-file-medical:before {
  content: '\e9f0'; }

/* '' */
.uil-file-minus-alt:before {
  content: '\e9f1'; }

/* '' */
.uil-file-minus:before {
  content: '\e9f2'; }

/* '' */
.uil-file-network:before {
  content: '\e9f3'; }

/* '' */
.uil-file-plus-alt:before {
  content: '\e9f4'; }

/* '' */
.uil-file-plus:before {
  content: '\e9f5'; }

/* '' */
.uil-file-question-alt:before {
  content: '\e9f6'; }

/* '' */
.uil-file-question:before {
  content: '\e9f7'; }

/* '' */
.uil-file-redo-alt:before {
  content: '\e9f8'; }

/* '' */
.uil-file-search-alt:before {
  content: '\e9f9'; }

/* '' */
.uil-file-share-alt:before {
  content: '\e9fa'; }

/* '' */
.uil-file-shield-alt:before {
  content: '\e9fb'; }

/* '' */
.uil-file-slash:before {
  content: '\e9fc'; }

/* '' */
.uil-file-times-alt:before {
  content: '\e9fd'; }

/* '' */
.uil-file-times:before {
  content: '\e9fe'; }

/* '' */
.uil-file-upload-alt:before {
  content: '\e9ff'; }

/* '' */
.uil-file-upload:before {
  content: '\ea00'; }

/* '' */
.uil-file:before {
  content: '\ea01'; }

/* '' */
.uil-files-landscapes-alt:before {
  content: '\ea02'; }

/* '' */
.uil-files-landscapes:before {
  content: '\ea03'; }

/* '' */
.uil-film:before {
  content: '\ea04'; }

/* '' */
.uil-filter-slash:before {
  content: '\ea05'; }

/* '' */
.uil-filter:before {
  content: '\ea06'; }

/* '' */
.uil-fire:before {
  content: '\ea07'; }

/* '' */
.uil-flask-potion:before {
  content: '\ea08'; }

/* '' */
.uil-flask:before {
  content: '\ea09'; }

/* '' */
.uil-flip-h-alt:before {
  content: '\ea0a'; }

/* '' */
.uil-flip-h:before {
  content: '\ea0b'; }

/* '' */
.uil-flip-v-alt:before {
  content: '\ea0c'; }

/* '' */
.uil-flip-v:before {
  content: '\ea0d'; }

/* '' */
.uil-flower:before {
  content: '\ea0e'; }

/* '' */
.uil-focus-add:before {
  content: '\ea0f'; }

/* '' */
.uil-focus-target:before {
  content: '\ea10'; }

/* '' */
.uil-focus:before {
  content: '\ea11'; }

/* '' */
.uil-folder-check:before {
  content: '\ea12'; }

/* '' */
.uil-folder-download:before {
  content: '\ea13'; }

/* '' */
.uil-folder-exclamation:before {
  content: '\ea14'; }

/* '' */
.uil-folder-heart:before {
  content: '\ea15'; }

/* '' */
.uil-folder-info:before {
  content: '\ea16'; }

/* '' */
.uil-folder-lock:before {
  content: '\ea17'; }

/* '' */
.uil-folder-medical:before {
  content: '\ea18'; }

/* '' */
.uil-folder-minus:before {
  content: '\ea19'; }

/* '' */
.uil-folder-network:before {
  content: '\ea1a'; }

/* '' */
.uil-folder-plus:before {
  content: '\ea1b'; }

/* '' */
.uil-folder-question:before {
  content: '\ea1c'; }

/* '' */
.uil-folder-slash:before {
  content: '\ea1d'; }

/* '' */
.uil-folder-times:before {
  content: '\ea1e'; }

/* '' */
.uil-folder-upload:before {
  content: '\ea1f'; }

/* '' */
.uil-folder:before {
  content: '\ea20'; }

/* '' */
.uil-food:before {
  content: '\ea21'; }

/* '' */
.uil-football-american:before {
  content: '\ea22'; }

/* '' */
.uil-football-ball:before {
  content: '\ea23'; }

/* '' */
.uil-football:before {
  content: '\ea24'; }

/* '' */
.uil-forecastcloud-moon-tear:before {
  content: '\ea25'; }

/* '' */
.uil-forwaded-call:before {
  content: '\ea26'; }

/* '' */
.uil-forward:before {
  content: '\ea27'; }

/* '' */
.uil-frown:before {
  content: '\ea28'; }

/* '' */
.uil-game-structure:before {
  content: '\ea29'; }

/* '' */
.uil-game:before {
  content: '\ea2a'; }

/* '' */
.uil-gift:before {
  content: '\ea2b'; }

/* '' */
.uil-github-alt:before {
  content: '\ea2c'; }

/* '' */
.uil-github:before {
  content: '\ea2d'; }

/* '' */
.uil-glass-martini-alt-slash:before {
  content: '\ea2e'; }

/* '' */
.uil-glass-martini-alt:before {
  content: '\ea2f'; }

/* '' */
.uil-glass-martini:before {
  content: '\ea30'; }

/* '' */
.uil-glass-tea:before {
  content: '\ea31'; }

/* '' */
.uil-glass:before {
  content: '\ea32'; }

/* '' */
.uil-globe:before {
  content: '\ea33'; }

/* '' */
.uil-gold:before {
  content: '\ea34'; }

/* '' */
.uil-google-drive-alt:before {
  content: '\ea35'; }

/* '' */
.uil-google-drive:before {
  content: '\ea36'; }

/* '' */
.uil-google-hangouts-alt:before {
  content: '\ea37'; }

/* '' */
.uil-google-hangouts:before {
  content: '\ea38'; }

/* '' */
.uil-google-play:before {
  content: '\ea39'; }

/* '' */
.uil-google:before {
  content: '\ea3a'; }

/* '' */
.uil-graduation-hat:before {
  content: '\ea3b'; }

/* '' */
.uil-graph-bar:before {
  content: '\ea3c'; }

/* '' */
.uil-grid:before {
  content: '\ea3d'; }

/* '' */
.uil-grids:before {
  content: '\ea3e'; }

/* '' */
.uil-grin-tongue-wink-alt:before {
  content: '\ea3f'; }

/* '' */
.uil-grin-tongue-wink:before {
  content: '\ea40'; }

/* '' */
.uil-grin:before {
  content: '\ea41'; }

/* '' */
.uil-grip-horizontal-line:before {
  content: '\ea42'; }

/* '' */
.uil-hdd:before {
  content: '\ea43'; }

/* '' */
.uil-headphones-alt:before {
  content: '\ea44'; }

/* '' */
.uil-headphones:before {
  content: '\ea45'; }

/* '' */
.uil-heart-alt:before {
  content: '\ea46'; }

/* '' */
.uil-heart-medical:before {
  content: '\ea47'; }

/* '' */
.uil-heart-rate:before {
  content: '\ea48'; }

/* '' */
.uil-heart-sign:before {
  content: '\ea49'; }

/* '' */
.uil-heart:before {
  content: '\ea4a'; }

/* '' */
.uil-heartbeat:before {
  content: '\ea4b'; }

/* '' */
.uil-history-alt:before {
  content: '\ea4c'; }

/* '' */
.uil-history:before {
  content: '\ea4d'; }

/* '' */
.uil-home-alt:before {
  content: '\ea4e'; }

/* '' */
.uil-home:before {
  content: '\ea4f'; }

/* '' */
.uil-horizontal-align-center:before {
  content: '\ea50'; }

/* '' */
.uil-horizontal-align-left:before {
  content: '\ea51'; }

/* '' */
.uil-horizontal-align-right:before {
  content: '\ea52'; }

/* '' */
.uil-horizontal-distribution-center:before {
  content: '\ea53'; }

/* '' */
.uil-horizontal-distribution-left:before {
  content: '\ea54'; }

/* '' */
.uil-horizontal-distribution-right:before {
  content: '\ea55'; }

/* '' */
.uil-hourglass:before {
  content: '\ea56'; }

/* '' */
.uil-html3-alt:before {
  content: '\ea57'; }

/* '' */
.uil-html3:before {
  content: '\ea58'; }

/* '' */
.uil-hunting:before {
  content: '\ea59'; }

/* '' */
.uil-image-alt-slash:before {
  content: '\ea5a'; }

/* '' */
.uil-image-block:before {
  content: '\ea5b'; }

/* '' */
.uil-image-broken:before {
  content: '\ea5c'; }

/* '' */
.uil-image-check:before {
  content: '\ea5d'; }

/* '' */
.uil-image-download:before {
  content: '\ea5e'; }

/* '' */
.uil-image-edit:before {
  content: '\ea5f'; }

/* '' */
.uil-image-lock:before {
  content: '\ea60'; }

/* '' */
.uil-image-minus:before {
  content: '\ea61'; }

/* '' */
.uil-image-plus:before {
  content: '\ea62'; }

/* '' */
.uil-image-question:before {
  content: '\ea63'; }

/* '' */
.uil-image-redo:before {
  content: '\ea64'; }

/* '' */
.uil-image-resize-landscape:before {
  content: '\ea65'; }

/* '' */
.uil-image-resize-square:before {
  content: '\ea66'; }

/* '' */
.uil-image-search:before {
  content: '\ea67'; }

/* '' */
.uil-image-share:before {
  content: '\ea68'; }

/* '' */
.uil-image-shield:before {
  content: '\ea69'; }

/* '' */
.uil-image-slash:before {
  content: '\ea6a'; }

/* '' */
.uil-image-times:before {
  content: '\ea6b'; }

/* '' */
.uil-image-upload:before {
  content: '\ea6c'; }

/* '' */
.uil-image-v:before {
  content: '\ea6d'; }

/* '' */
.uil-image:before {
  content: '\ea6e'; }

/* '' */
.uil-images:before {
  content: '\ea6f'; }

/* '' */
.uil-import:before {
  content: '\ea70'; }

/* '' */
.uil-incoming-call:before {
  content: '\ea71'; }

/* '' */
.uil-info-circle:before {
  content: '\ea72'; }

/* '' */
.uil-instagram-alt:before {
  content: '\ea73'; }

/* '' */
.uil-instagram:before {
  content: '\ea74'; }

/* '' */
.uil-intercom-alt:before {
  content: '\ea75'; }

/* '' */
.uil-intercom:before {
  content: '\ea76'; }

/* '' */
.uil-invoice:before {
  content: '\ea77'; }

/* '' */
.uil-italic:before {
  content: '\ea78'; }

/* '' */
.uil-jackhammer:before {
  content: '\ea79'; }

/* '' */
.uil-java-script:before {
  content: '\ea7a'; }

/* '' */
.uil-kayak:before {
  content: '\ea7b'; }

/* '' */
.uil-key-skeleton-alt:before {
  content: '\ea7c'; }

/* '' */
.uil-key-skeleton:before {
  content: '\ea7d'; }

/* '' */
.uil-keyboard-alt:before {
  content: '\ea7e'; }

/* '' */
.uil-keyboard-hide:before {
  content: '\ea7f'; }

/* '' */
.uil-keyboard-show:before {
  content: '\ea80'; }

/* '' */
.uil-keyboard:before {
  content: '\ea81'; }

/* '' */
.uil-keyhole-circle:before {
  content: '\ea82'; }

/* '' */
.uil-keyhole-square-full:before {
  content: '\ea83'; }

/* '' */
.uil-keyhole-square:before {
  content: '\ea84'; }

/* '' */
.uil-kid:before {
  content: '\ea85'; }

/* '' */
.uil-label-alt:before {
  content: '\ea86'; }

/* '' */
.uil-label:before {
  content: '\ea87'; }

/* '' */
.uil-lamp:before {
  content: '\ea88'; }

/* '' */
.uil-laptop-cloud:before {
  content: '\ea89'; }

/* '' */
.uil-laptop:before {
  content: '\ea8a'; }

/* '' */
.uil-laughing:before {
  content: '\ea8b'; }

/* '' */
.uil-layer-group-slash:before {
  content: '\ea8c'; }

/* '' */
.uil-layer-group:before {
  content: '\ea8d'; }

/* '' */
.uil-layers-alt:before {
  content: '\ea8e'; }

/* '' */
.uil-layers-slash:before {
  content: '\ea8f'; }

/* '' */
.uil-layers:before {
  content: '\ea90'; }

/* '' */
.uil-left-arrow-from-left:before {
  content: '\ea91'; }

/* '' */
.uil-left-arrow-to-left:before {
  content: '\ea92'; }

/* '' */
.uil-left-indent-alt:before {
  content: '\ea93'; }

/* '' */
.uil-left-indent:before {
  content: '\ea94'; }

/* '' */
.uil-left-to-right-text-direction:before {
  content: '\ea95'; }

/* '' */
.uil-life-ring:before {
  content: '\ea96'; }

/* '' */
.uil-lightbulb-alt:before {
  content: '\ea97'; }

/* '' */
.uil-lightbulb:before {
  content: '\ea98'; }

/* '' */
.uil-line-alt:before {
  content: '\ea99'; }

/* '' */
.uil-line-spacing:before {
  content: '\ea9a'; }

/* '' */
.uil-line:before {
  content: '\ea9b'; }

/* '' */
.uil-link-alt:before {
  content: '\ea9c'; }

/* '' */
.uil-link-broken:before {
  content: '\ea9d'; }

/* '' */
.uil-link-h:before {
  content: '\ea9e'; }

/* '' */
.uil-link:before {
  content: '\ea9f'; }

/* '' */
.uil-linkedin-alt:before {
  content: '\eaa0'; }

/* '' */
.uil-linkedin:before {
  content: '\eaa1'; }

/* '' */
.uil-list-ui-alt:before {
  content: '\eaa2'; }

/* '' */
.uil-list-ul:before {
  content: '\eaa3'; }

/* '' */
.uil-location-arrow-alt:before {
  content: '\eaa4'; }

/* '' */
.uil-location-arrow:before {
  content: '\eaa5'; }

/* '' */
.uil-location-pin-alt:before {
  content: '\eaa6'; }

/* '' */
.uil-location-point:before {
  content: '\eaa7'; }

/* '' */
.uil-location:before {
  content: '\eaa8'; }

/* '' */
.uil-lock-access:before {
  content: '\eaa9'; }

/* '' */
.uil-lock-alt:before {
  content: '\eaaa'; }

/* '' */
.uil-lock-open-alt:before {
  content: '\eaab'; }

/* '' */
.uil-lock-slash:before {
  content: '\eaac'; }

/* '' */
.uil-lock:before {
  content: '\eaad'; }

/* '' */
.uil-mailbox-alt:before {
  content: '\eaae'; }

/* '' */
.uil-mailbox:before {
  content: '\eaaf'; }

/* '' */
.uil-map-marker-alt:before {
  content: '\eab0'; }

/* '' */
.uil-map-marker-edit:before {
  content: '\eab1'; }

/* '' */
.uil-map-marker-info:before {
  content: '\eab2'; }

/* '' */
.uil-map-marker-minus:before {
  content: '\eab3'; }

/* '' */
.uil-map-marker-plus:before {
  content: '\eab4'; }

/* '' */
.uil-map-marker-question:before {
  content: '\eab5'; }

/* '' */
.uil-map-marker-shield:before {
  content: '\eab6'; }

/* '' */
.uil-map-marker-slash:before {
  content: '\eab7'; }

/* '' */
.uil-map-marker:before {
  content: '\eab8'; }

/* '' */
.uil-map-pin-alt:before {
  content: '\eab9'; }

/* '' */
.uil-map-pin:before {
  content: '\eaba'; }

/* '' */
.uil-map:before {
  content: '\eabb'; }

/* '' */
.uil-mars:before {
  content: '\eabc'; }

/* '' */
.uil-master-card:before {
  content: '\eabd'; }

/* '' */
.uil-maximize-left:before {
  content: '\eabe'; }

/* '' */
.uil-medal:before {
  content: '\eabf'; }

/* '' */
.uil-medical-drip:before {
  content: '\eac0'; }

/* '' */
.uil-medical-square-full:before {
  content: '\eac1'; }

/* '' */
.uil-medical-square:before {
  content: '\eac2'; }

/* '' */
.uil-medical:before {
  content: '\eac3'; }

/* '' */
.uil-medium-m:before {
  content: '\eac4'; }

/* '' */
.uil-medkit:before {
  content: '\eac5'; }

/* '' */
.uil-meeting-board:before {
  content: '\eac6'; }

/* '' */
.uil-megaphone:before {
  content: '\eac7'; }

/* '' */
.uil-meh-alt:before {
  content: '\eac8'; }

/* '' */
.uil-meh-closed-eye:before {
  content: '\eac9'; }

/* '' */
.uil-meh:before {
  content: '\eaca'; }

/* '' */
.uil-message:before {
  content: '\eacb'; }

/* '' */
.uil-metro:before {
  content: '\eacc'; }

/* '' */
.uil-microphone-slash:before {
  content: '\eacd'; }

/* '' */
.uil-microphone:before {
  content: '\eace'; }

/* '' */
.uil-minus-circle:before {
  content: '\eacf'; }

/* '' */
.uil-minus-path:before {
  content: '\ead0'; }

/* '' */
.uil-minus-square-full:before {
  content: '\ead1'; }

/* '' */
.uil-minus-square:before {
  content: '\ead2'; }

/* '' */
.uil-minus:before {
  content: '\ead3'; }

/* '' */
.uil-missed-call:before {
  content: '\ead4'; }

/* '' */
.uil-mobey-bill-slash:before {
  content: '\ead5'; }

/* '' */
.uil-mobile-android-alt:before {
  content: '\ead6'; }

/* '' */
.uil-mobile-android:before {
  content: '\ead7'; }

/* '' */
.uil-mobile-vibrate:before {
  content: '\ead8'; }

/* '' */
.uil-modem:before {
  content: '\ead9'; }

/* '' */
.uil-money-bill-stack:before {
  content: '\eada'; }

/* '' */
.uil-money-bill:before {
  content: '\eadb'; }

/* '' */
.uil-money-insert:before {
  content: '\eadc'; }

/* '' */
.uil-money-stack:before {
  content: '\eadd'; }

/* '' */
.uil-money-withdraw:before {
  content: '\eade'; }

/* '' */
.uil-money-withdrawal:before {
  content: '\eadf'; }

/* '' */
.uil-moneybag-alt:before {
  content: '\eae0'; }

/* '' */
.uil-moneybag:before {
  content: '\eae1'; }

/* '' */
.uil-monitor-heart-rate:before {
  content: '\eae2'; }

/* '' */
.uil-monitor:before {
  content: '\eae3'; }

/* '' */
.uil-moon-eclipse:before {
  content: '\eae4'; }

/* '' */
.uil-moon:before {
  content: '\eae5'; }

/* '' */
.uil-moonset:before {
  content: '\eae6'; }

/* '' */
.uil-mountains-sun:before {
  content: '\eae7'; }

/* '' */
.uil-mountains:before {
  content: '\eae8'; }

/* '' */
.uil-mouse-alt:before {
  content: '\eae9'; }

/* '' */
.uil-mouse:before {
  content: '\eaea'; }

/* '' */
.uil-multiply:before {
  content: '\eaeb'; }

/* '' */
.uil-music-note:before {
  content: '\eaec'; }

/* '' */
.uil-music-tune-slash:before {
  content: '\eaed'; }

/* '' */
.uil-music:before {
  content: '\eaee'; }

/* '' */
.uil-n-a:before {
  content: '\eaef'; }

/* '' */
.uil-navigator:before {
  content: '\eaf0'; }

/* '' */
.uil-nerd:before {
  content: '\eaf1'; }

/* '' */
.uil-newspaper:before {
  content: '\eaf2'; }

/* '' */
.uil-ninja:before {
  content: '\eaf3'; }

/* '' */
.uil-no-entry:before {
  content: '\eaf4'; }

/* '' */
.uil-notebooks:before {
  content: '\eaf5'; }

/* '' */
.uil-notes:before {
  content: '\eaf6'; }

/* '' */
.uil-object-group:before {
  content: '\eaf7'; }

/* '' */
.uil-object-ungroup:before {
  content: '\eaf8'; }

/* '' */
.uil-octagon:before {
  content: '\eaf9'; }

/* '' */
.uil-opera-alt:before {
  content: '\eafa'; }

/* '' */
.uil-opera:before {
  content: '\eafb'; }

/* '' */
.uil-outgoing-call:before {
  content: '\eafc'; }

/* '' */
.uil-package:before {
  content: '\eafd'; }

/* '' */
.uil-padlock:before {
  content: '\eafe'; }

/* '' */
.uil-paint-tool:before {
  content: '\eaff'; }

/* '' */
.uil-palette:before {
  content: '\eb00'; }

/* '' */
.uil-panorama-h-alt:before {
  content: '\eb01'; }

/* '' */
.uil-panorama-h:before {
  content: '\eb02'; }

/* '' */
.uil-panorama-v:before {
  content: '\eb03'; }

/* '' */
.uil-paperclip:before {
  content: '\eb04'; }

/* '' */
.uil-paragraph:before {
  content: '\eb05'; }

/* '' */
.uil-parcel:before {
  content: '\eb06'; }

/* '' */
.uil-parking-square:before {
  content: '\eb07'; }

/* '' */
.uil-pathfinder-unite:before {
  content: '\eb08'; }

/* '' */
.uil-pathfinder:before {
  content: '\eb09'; }

/* '' */
.uil-pause-circle:before {
  content: '\eb0a'; }

/* '' */
.uil-pause:before {
  content: '\eb0b'; }

/* '' */
.uil-paypal:before {
  content: '\eb0c'; }

/* '' */
.uil-pen:before {
  content: '\eb0d'; }

/* '' */
.uil-pentagon:before {
  content: '\eb0e'; }

/* '' */
.uil-percentage:before {
  content: '\eb0f'; }

/* '' */
.uil-phone-alt:before {
  content: '\eb10'; }

/* '' */
.uil-phone-pause:before {
  content: '\eb11'; }

/* '' */
.uil-phone-slash:before {
  content: '\eb12'; }

/* '' */
.uil-phone-times:before {
  content: '\eb13'; }

/* '' */
.uil-phone-volume:before {
  content: '\eb14'; }

/* '' */
.uil-phone:before {
  content: '\eb15'; }

/* '' */
.uil-picture:before {
  content: '\eb16'; }

/* '' */
.uil-plane-arrival:before {
  content: '\eb17'; }

/* '' */
.uil-plane-departure:before {
  content: '\eb18'; }

/* '' */
.uil-plane-fly:before {
  content: '\eb19'; }

/* '' */
.uil-plane:before {
  content: '\eb1a'; }

/* '' */
.uil-play-circle:before {
  content: '\eb1b'; }

/* '' */
.uil-play:before {
  content: '\eb1c'; }

/* '' */
.uil-plug:before {
  content: '\eb1d'; }

/* '' */
.uil-plus-circle:before {
  content: '\eb1e'; }

/* '' */
.uil-plus-square:before {
  content: '\eb1f'; }

/* '' */
.uil-plus:before {
  content: '\eb20'; }

/* '' */
.uil-podium:before {
  content: '\eb21'; }

/* '' */
.uil-polygon:before {
  content: '\eb22'; }

/* '' */
.uil-post-stamp:before {
  content: '\eb23'; }

/* '' */
.uil-postcard:before {
  content: '\eb24'; }

/* '' */
.uil-pound-circle:before {
  content: '\eb25'; }

/* '' */
.uil-pound:before {
  content: '\eb26'; }

/* '' */
.uil-power:before {
  content: '\eb27'; }

/* '' */
.uil-prescription-bottle:before {
  content: '\eb28'; }

/* '' */
.uil-presentation-check:before {
  content: '\eb29'; }

/* '' */
.uil-presentation-edit:before {
  content: '\eb2a'; }

/* '' */
.uil-presentation-line:before {
  content: '\eb2b'; }

/* '' */
.uil-presentation-lines-alt:before {
  content: '\eb2c'; }

/* '' */
.uil-presentation-minus:before {
  content: '\eb2d'; }

/* '' */
.uil-presentation-play:before {
  content: '\eb2e'; }

/* '' */
.uil-presentation-plus:before {
  content: '\eb2f'; }

/* '' */
.uil-presentation-times:before {
  content: '\eb30'; }

/* '' */
.uil-presentation:before {
  content: '\eb31'; }

/* '' */
.uil-previous:before {
  content: '\eb32'; }

/* '' */
.uil-pricetag-alt:before {
  content: '\eb33'; }

/* '' */
.uil-print-slash:before {
  content: '\eb34'; }

/* '' */
.uil-print:before {
  content: '\eb35'; }

/* '' */
.uil-process:before {
  content: '\eb36'; }

/* '' */
.uil-processor:before {
  content: '\eb37'; }

/* '' */
.uil-pump:before {
  content: '\eb38'; }

/* '' */
.uil-puzzle-piece:before {
  content: '\eb39'; }

/* '' */
.uil-question-circle:before {
  content: '\eb3a'; }

/* '' */
.uil-raddit-alien-alt:before {
  content: '\eb3b'; }

/* '' */
.uil-rainbow:before {
  content: '\eb3c'; }

/* '' */
.uil-raindrops-alt:before {
  content: '\eb3d'; }

/* '' */
.uil-raindrops:before {
  content: '\eb3e'; }

/* '' */
.uil-react:before {
  content: '\eb3f'; }

/* '' */
.uil-receipt-alt:before {
  content: '\eb40'; }

/* '' */
.uil-receipt:before {
  content: '\eb41'; }

/* '' */
.uil-record-audio:before {
  content: '\eb42'; }

/* '' */
.uil-reddit-alien-alt:before {
  content: '\eb43'; }

/* '' */
.uil-redo:before {
  content: '\eb44'; }

/* '' */
.uil-refresh:before {
  content: '\eb45'; }

/* '' */
.uil-registered:before {
  content: '\eb46'; }

/* '' */
.uil-repeat:before {
  content: '\eb47'; }

/* '' */
.uil-restaurant:before {
  content: '\eb48'; }

/* '' */
.uil-right-indent-alt:before {
  content: '\eb49'; }

/* '' */
.uil-right-to-left-text-direction:before {
  content: '\eb4a'; }

/* '' */
.uil-robot:before {
  content: '\eb4b'; }

/* '' */
.uil-rope-way:before {
  content: '\eb4c'; }

/* '' */
.uil-rotate-360:before {
  content: '\eb4d'; }

/* '' */
.uil-rss-alt:before {
  content: '\eb4e'; }

/* '' */
.uil-rss-interface:before {
  content: '\eb4f'; }

/* '' */
.uil-rss:before {
  content: '\eb50'; }

/* '' */
.uil-ruler-combined:before {
  content: '\eb51'; }

/* '' */
.uil-ruler:before {
  content: '\eb52'; }

/* '' */
.uil-sad-cry:before {
  content: '\eb53'; }

/* '' */
.uil-sad-crying:before {
  content: '\eb54'; }

/* '' */
.uil-sad-dizzy:before {
  content: '\eb55'; }

/* '' */
.uil-sad-squint:before {
  content: '\eb56'; }

/* '' */
.uil-sad:before {
  content: '\eb57'; }

/* '' */
.uil-scaling-left:before {
  content: '\eb58'; }

/* '' */
.uil-scaling-right:before {
  content: '\eb59'; }

/* '' */
.uil-scenery:before {
  content: '\eb5a'; }

/* '' */
.uil-schedule:before {
  content: '\eb5b'; }

/* '' */
.uil-science:before {
  content: '\eb5c'; }

/* '' */
.uil-screw:before {
  content: '\eb5d'; }

/* '' */
.uil-scroll-h:before {
  content: '\eb5e'; }

/* '' */
.uil-scroll:before {
  content: '\eb5f'; }

/* '' */
.uil-search-alt:before {
  content: '\eb60'; }

/* '' */
.uil-search-minus:before {
  content: '\eb61'; }

/* '' */
.uil-search-plus:before {
  content: '\eb62'; }

/* '' */
.uil-search:before {
  content: '\eb63'; }

/* '' */
.uil-selfie:before {
  content: '\eb64'; }

/* '' */
.uil-server-alt:before {
  content: '\eb65'; }

/* '' */
.uil-server-connection:before {
  content: '\eb66'; }

/* '' */
.uil-server-network-alt:before {
  content: '\eb67'; }

/* '' */
.uil-server-network:before {
  content: '\eb68'; }

/* '' */
.uil-server:before {
  content: '\eb69'; }

/* '' */
.uil-servers:before {
  content: '\eb6a'; }

/* '' */
.uil-servicemark:before {
  content: '\eb6b'; }

/* '' */
.uil-share-alt:before {
  content: '\eb6c'; }

/* '' */
.uil-shield-check:before {
  content: '\eb6d'; }

/* '' */
.uil-shield-exclamation:before {
  content: '\eb6e'; }

/* '' */
.uil-shield-question:before {
  content: '\eb6f'; }

/* '' */
.uil-shield-slash:before {
  content: '\eb70'; }

/* '' */
.uil-shield:before {
  content: '\eb71'; }

/* '' */
.uil-ship:before {
  content: '\eb72'; }

/* '' */
.uil-shop:before {
  content: '\eb73'; }

/* '' */
.uil-shopping-basket:before {
  content: '\eb74'; }

/* '' */
.uil-shopping-cart-alt:before {
  content: '\eb75'; }

/* '' */
.uil-shopping-trolley:before {
  content: '\eb76'; }

/* '' */
.uil-shovel:before {
  content: '\eb77'; }

/* '' */
.uil-shrink:before {
  content: '\eb78'; }

/* '' */
.uil-shuffle:before {
  content: '\eb79'; }

/* '' */
.uil-shutter-alt:before {
  content: '\eb7a'; }

/* '' */
.uil-shutter:before {
  content: '\eb7b'; }

/* '' */
.uil-sick:before {
  content: '\eb7c'; }

/* '' */
.uil-sigma:before {
  content: '\eb7d'; }

/* '' */
.uil-sign-alt:before {
  content: '\eb7e'; }

/* '' */
.uil-sign-in-alt:before {
  content: '\eb7f'; }

/* '' */
.uil-sign-left:before {
  content: '\eb80'; }

/* '' */
.uil-sign-out-alt:before {
  content: '\eb81'; }

/* '' */
.uil-sign-right:before {
  content: '\eb82'; }

/* '' */
.uil-signal-alt-3:before {
  content: '\eb83'; }

/* '' */
.uil-signal-alt:before {
  content: '\eb84'; }

/* '' */
.uil-signal:before {
  content: '\eb85'; }

/* '' */
.uil-silence:before {
  content: '\eb86'; }

/* '' */
.uil-silent-squint:before {
  content: '\eb87'; }

/* '' */
.uil-sim-card:before {
  content: '\eb88'; }

/* '' */
.uil-sitemap:before {
  content: '\eb89'; }

/* '' */
.uil-skip-forward-alt:before {
  content: '\eb8a'; }

/* '' */
.uil-skip-forward-circle:before {
  content: '\eb8b'; }

/* '' */
.uil-skip-forward:before {
  content: '\eb8c'; }

/* '' */
.uil-skype-alt:before {
  content: '\eb8d'; }

/* '' */
.uil-skype:before {
  content: '\eb8e'; }

/* '' */
.uil-slack-alt:before {
  content: '\eb8f'; }

/* '' */
.uil-slack:before {
  content: '\eb90'; }

/* '' */
.uil-sliders-v-alt:before {
  content: '\eb91'; }

/* '' */
.uil-sliders-v:before {
  content: '\eb92'; }

/* '' */
.uil-smile-beam:before {
  content: '\eb93'; }

/* '' */
.uil-smile-dizzy:before {
  content: '\eb94'; }

/* '' */
.uil-smile-squint-wink-alt:before {
  content: '\eb95'; }

/* '' */
.uil-smile-squint-wink:before {
  content: '\eb96'; }

/* '' */
.uil-smile-wink-alt:before {
  content: '\eb97'; }

/* '' */
.uil-smile-wink:before {
  content: '\eb98'; }

/* '' */
.uil-smile:before {
  content: '\eb99'; }

/* '' */
.uil-snapchat-alt:before {
  content: '\eb9a'; }

/* '' */
.uil-snapchat-ghost:before {
  content: '\eb9b'; }

/* '' */
.uil-snapchat-square:before {
  content: '\eb9c'; }

/* '' */
.uil-snow-flake:before {
  content: '\eb9d'; }

/* '' */
.uil-snowflake-alt:before {
  content: '\eb9e'; }

/* '' */
.uil-snowflake:before {
  content: '\eb9f'; }

/* '' */
.uil-sort-amount-down:before {
  content: '\eba0'; }

/* '' */
.uil-sort-amount-up:before {
  content: '\eba1'; }

/* '' */
.uil-sort:before {
  content: '\eba2'; }

/* '' */
.uil-sorting:before {
  content: '\eba3'; }

/* '' */
.uil-space-key:before {
  content: '\eba4'; }

/* '' */
.uil-spade:before {
  content: '\eba5'; }

/* '' */
.uil-sperms:before {
  content: '\eba6'; }

/* '' */
.uil-spin:before {
  content: '\eba7'; }

/* '' */
.uil-sport:before {
  content: '\eba8'; }

/* '' */
.uil-square-full:before {
  content: '\eba9'; }

/* '' */
.uil-square-shape:before {
  content: '\ebaa'; }

/* '' */
.uil-square:before {
  content: '\ebab'; }

/* '' */
.uil-squint:before {
  content: '\ebac'; }

/* '' */
.uil-star-half-alt:before {
  content: '\ebad'; }

/* '' */
.uil-star:before {
  content: '\ebae'; }

/* '' */
.uil-step-backward-alt:before {
  content: '\ebaf'; }

/* '' */
.uil-step-backward-circle:before {
  content: '\ebb0'; }

/* '' */
.uil-step-backward:before {
  content: '\ebb1'; }

/* '' */
.uil-step-forward:before {
  content: '\ebb2'; }

/* '' */
.uil-stop-circle:before {
  content: '\ebb3'; }

/* '' */
.uil-stopwatch-slash:before {
  content: '\ebb4'; }

/* '' */
.uil-stopwatch:before {
  content: '\ebb5'; }

/* '' */
.uil-store-alt:before {
  content: '\ebb6'; }

/* '' */
.uil-store:before {
  content: '\ebb7'; }

/* '' */
.uil-streering:before {
  content: '\ebb8'; }

/* '' */
.uil-stretcher:before {
  content: '\ebb9'; }

/* '' */
.uil-subject:before {
  content: '\ebba'; }

/* '' */
.uil-subway-alt:before {
  content: '\ebbb'; }

/* '' */
.uil-subway:before {
  content: '\ebbc'; }

/* '' */
.uil-suitcase-alt:before {
  content: '\ebbd'; }

/* '' */
.uil-suitcase:before {
  content: '\ebbe'; }

/* '' */
.uil-sun:before {
  content: '\ebbf'; }

/* '' */
.uil-sunset:before {
  content: '\ebc0'; }

/* '' */
.uil-surprise:before {
  content: '\ebc1'; }

/* '' */
.uil-swatchbook:before {
  content: '\ebc2'; }

/* '' */
.uil-swiggy:before {
  content: '\ebc3'; }

/* '' */
.uil-swimmer:before {
  content: '\ebc4'; }

/* '' */
.uil-symbol:before {
  content: '\ebc5'; }

/* '' */
.uil-sync-exclamation:before {
  content: '\ebc6'; }

/* '' */
.uil-sync-slash:before {
  content: '\ebc7'; }

/* '' */
.uil-sync:before {
  content: '\ebc8'; }

/* '' */
.uil-syringe:before {
  content: '\ebc9'; }

/* '' */
.uil-table:before {
  content: '\ebca'; }

/* '' */
.uil-tablet:before {
  content: '\ebcb'; }

/* '' */
.uil-tablets:before {
  content: '\ebcc'; }

/* '' */
.uil-tachometer-fast:before {
  content: '\ebcd'; }

/* '' */
.uil-tag-alt:before {
  content: '\ebce'; }

/* '' */
.uil-tag:before {
  content: '\ebcf'; }

/* '' */
.uil-tape:before {
  content: '\ebd0'; }

/* '' */
.uil-taxi:before {
  content: '\ebd1'; }

/* '' */
.uil-tear:before {
  content: '\ebd2'; }

/* '' */
.uil-technology:before {
  content: '\ebd3'; }

/* '' */
.uil-telegram-alt:before {
  content: '\ebd4'; }

/* '' */
.uil-telegram:before {
  content: '\ebd5'; }

/* '' */
.uil-telescope:before {
  content: '\ebd6'; }

/* '' */
.uil-temperature-empty:before {
  content: '\ebd7'; }

/* '' */
.uil-temperature-half:before {
  content: '\ebd8'; }

/* '' */
.uil-temperature-minus:before {
  content: '\ebd9'; }

/* '' */
.uil-temperature-plus:before {
  content: '\ebda'; }

/* '' */
.uil-temperature-quarter:before {
  content: '\ebdb'; }

/* '' */
.uil-temperature-three-quarter:before {
  content: '\ebdc'; }

/* '' */
.uil-temperature:before {
  content: '\ebdd'; }

/* '' */
.uil-text-fields:before {
  content: '\ebde'; }

/* '' */
.uil-text-size:before {
  content: '\ebdf'; }

/* '' */
.uil-text-strike-through:before {
  content: '\ebe0'; }

/* '' */
.uil-text:before {
  content: '\ebe1'; }

/* '' */
.uil-th-large:before {
  content: '\ebe2'; }

/* '' */
.uil-th-slash:before {
  content: '\ebe3'; }

/* '' */
.uil-th:before {
  content: '\ebe4'; }

/* '' */
.uil-thermometer:before {
  content: '\ebe5'; }

/* '' */
.uil-thumbs-down:before {
  content: '\ebe6'; }

/* '' */
.uil-thumbs-up:before {
  content: '\ebe7'; }

/* '' */
.uil-thunderstorm-moon:before {
  content: '\ebe8'; }

/* '' */
.uil-thunderstorm-sun:before {
  content: '\ebe9'; }

/* '' */
.uil-thunderstorm:before {
  content: '\ebea'; }

/* '' */
.uil-ticket:before {
  content: '\ebeb'; }

/* '' */
.uil-times-circle:before {
  content: '\ebec'; }

/* '' */
.uil-times-square:before {
  content: '\ebed'; }

/* '' */
.uil-times:before {
  content: '\ebee'; }

/* '' */
.uil-toggle-off:before {
  content: '\ebef'; }

/* '' */
.uil-toggle-on:before {
  content: '\ebf0'; }

/* '' */
.uil-top-arrow-from-top:before {
  content: '\ebf1'; }

/* '' */
.uil-top-arrow-to-top:before {
  content: '\ebf2'; }

/* '' */
.uil-tornado:before {
  content: '\ebf3'; }

/* '' */
.uil-trademark-circle:before {
  content: '\ebf4'; }

/* '' */
.uil-trademark:before {
  content: '\ebf5'; }

/* '' */
.uil-traffic-barrier:before {
  content: '\ebf6'; }

/* '' */
.uil-trash-alt:before {
  content: '\ebf7'; }

/* '' */
.uil-trash:before {
  content: '\ebf8'; }

/* '' */
.uil-trees:before {
  content: '\ebf9'; }

/* '' */
.uil-triangle:before {
  content: '\ebfa'; }

/* '' */
.uil-trophy:before {
  content: '\ebfb'; }

/* '' */
.uil-trowel:before {
  content: '\ebfc'; }

/* '' */
.uil-truck-case:before {
  content: '\ebfd'; }

/* '' */
.uil-truck-loading:before {
  content: '\ebfe'; }

/* '' */
.uil-truck:before {
  content: '\ebff'; }

/* '' */
.uil-tumblr-alt:before {
  content: '\ec00'; }

/* '' */
.uil-tumblr-square:before {
  content: '\ec01'; }

/* '' */
.uil-tumblr:before {
  content: '\ec02'; }

/* '' */
.uil-tv-retro-slash:before {
  content: '\ec03'; }

/* '' */
.uil-tv-retro:before {
  content: '\ec04'; }

/* '' */
.uil-twitter-alt:before {
  content: '\ec05'; }

/* '' */
.uil-twitter:before {
  content: '\ec06'; }

/* '' */
.uil-umbrella:before {
  content: '\ec07'; }

/* '' */
.uil-unamused:before {
  content: '\ec08'; }

/* '' */
.uil-underline:before {
  content: '\ec09'; }

/* '' */
.uil-university:before {
  content: '\ec0a'; }

/* '' */
.uil-unlock-alt:before {
  content: '\ec0b'; }

/* '' */
.uil-unlock:before {
  content: '\ec0c'; }

/* '' */
.uil-upload-alt:before {
  content: '\ec0d'; }

/* '' */
.uil-upload:before {
  content: '\ec0e'; }

/* '' */
.uil-usd-circle:before {
  content: '\ec0f'; }

/* '' */
.uil-usd-square:before {
  content: '\ec10'; }

/* '' */
.uil-user-check:before {
  content: '\ec11'; }

/* '' */
.uil-user-circle:before {
  content: '\ec12'; }

/* '' */
.uil-user-exclamation:before {
  content: '\ec13'; }

/* '' */
.uil-user-hard-hat:before {
  content: '\ec14'; }

/* '' */
.uil-user-minus:before {
  content: '\ec15'; }

/* '' */
.uil-user-plus:before {
  content: '\ec16'; }

/* '' */
.uil-user-square:before {
  content: '\ec17'; }

/* '' */
.uil-user-times:before {
  content: '\ec18'; }

/* '' */
.uil-user:before {
  content: '\ec19'; }

/* '' */
.uil-users-alt:before {
  content: '\ec1a'; }

/* '' */
.uil-utensils-alt:before {
  content: '\ec1b'; }

/* '' */
.uil-utensils:before {
  content: '\ec1c'; }

/* '' */
.uil-vector-square-alt:before {
  content: '\ec1d'; }

/* '' */
.uil-vector-square:before {
  content: '\ec1e'; }

/* '' */
.uil-venus:before {
  content: '\ec1f'; }

/* '' */
.uil-vertical-align-bottom:before {
  content: '\ec20'; }

/* '' */
.uil-vertical-align-center:before {
  content: '\ec21'; }

/* '' */
.uil-vertical-align-top:before {
  content: '\ec22'; }

/* '' */
.uil-vertical-distribute-bottom:before {
  content: '\ec23'; }

/* '' */
.uil-vertical-distribution-center:before {
  content: '\ec24'; }

/* '' */
.uil-vertical-distribution-top:before {
  content: '\ec25'; }

/* '' */
.uil-video-slash:before {
  content: '\ec26'; }

/* '' */
.uil-video:before {
  content: '\ec27'; }

/* '' */
.uil-visual-studio:before {
  content: '\ec28'; }

/* '' */
.uil-vk-alt:before {
  content: '\ec29'; }

/* '' */
.uil-vk:before {
  content: '\ec2a'; }

/* '' */
.uil-voicemail-rectangle:before {
  content: '\ec2b'; }

/* '' */
.uil-voicemail:before {
  content: '\ec2c'; }

/* '' */
.uil-volleyball:before {
  content: '\ec2d'; }

/* '' */
.uil-volume-down:before {
  content: '\ec2e'; }

/* '' */
.uil-volume-mute:before {
  content: '\ec2f'; }

/* '' */
.uil-volume-off:before {
  content: '\ec30'; }

/* '' */
.uil-volume-up:before {
  content: '\ec31'; }

/* '' */
.uil-volume:before {
  content: '\ec32'; }

/* '' */
.uil-vuejs-alt:before {
  content: '\ec33'; }

/* '' */
.uil-vuejs:before {
  content: '\ec34'; }

/* '' */
.uil-wall:before {
  content: '\ec35'; }

/* '' */
.uil-wallet:before {
  content: '\ec36'; }

/* '' */
.uil-watch-alt:before {
  content: '\ec37'; }

/* '' */
.uil-watch:before {
  content: '\ec38'; }

/* '' */
.uil-water-drop-slash:before {
  content: '\ec39'; }

/* '' */
.uil-water-glass:before {
  content: '\ec3a'; }

/* '' */
.uil-water:before {
  content: '\ec3b'; }

/* '' */
.uil-web-grid-alt:before {
  content: '\ec3c'; }

/* '' */
.uil-web-grid:before {
  content: '\ec3d'; }

/* '' */
.uil-web-section-alt:before {
  content: '\ec3e'; }

/* '' */
.uil-web-section:before {
  content: '\ec3f'; }

/* '' */
.uil-webcam:before {
  content: '\ec40'; }

/* '' */
.uil-weight:before {
  content: '\ec41'; }

/* '' */
.uil-whatsapp:before {
  content: '\ec42'; }

/* '' */
.uil-wheel-barrow:before {
  content: '\ec43'; }

/* '' */
.uil-wheelchair-alt:before {
  content: '\ec44'; }

/* '' */
.uil-wheelchair:before {
  content: '\ec45'; }

/* '' */
.uil-wifi-router:before {
  content: '\ec46'; }

/* '' */
.uil-wifi-slash:before {
  content: '\ec47'; }

/* '' */
.uil-wifi:before {
  content: '\ec48'; }

/* '' */
.uil-wind-moon:before {
  content: '\ec49'; }

/* '' */
.uil-wind-sun:before {
  content: '\ec4a'; }

/* '' */
.uil-wind:before {
  content: '\ec4b'; }

/* '' */
.uil-window-grid:before {
  content: '\ec4c'; }

/* '' */
.uil-window-maximize:before {
  content: '\ec4d'; }

/* '' */
.uil-window-restore:before {
  content: '\ec4e'; }

/* '' */
.uil-window-section:before {
  content: '\ec4f'; }

/* '' */
.uil-window:before {
  content: '\ec50'; }

/* '' */
.uil-windsock:before {
  content: '\ec51'; }

/* '' */
.uil-wrap-text:before {
  content: '\ec52'; }

/* '' */
.uil-wrench:before {
  content: '\ec53'; }

/* '' */
.uil-yellow:before {
  content: '\ec54'; }

/* '' */
.uil-yen-circle:before {
  content: '\ec55'; }

/* '' */
.uil-yen:before {
  content: '\ec56'; }

/* '' */
.uil-yin-yang:before {
  content: '\ec57'; }

/* '' */
.uil-youtube-alt:before {
  content: '\ec58'; }

/* '' */
.uil-youtube:before {
  content: '\ec59'; }

/* '' */
